var leadslide = $('#leadslideOverlay');
var hasToPage = $('.admin-templates-custom-index');

$('.previewPop').click(function (e) {
    e.preventDefault();
    var parent = $(this).parent().attr('id');
    //remove a links
    $('#'+parent).find('.previewContent .btn').removeAttr('href').addClass('disabled').attr({disabled:'disabled'});



    leadslide.html('');//reset

    leadslide.append($('#'+parent).find('.previewContent').html());
    leadslide.css({
        visibility: 'visible'
    });
});

leadslide.click(function() {
    $(this).css({
        visibility: 'hidden'
    });
});




