(function () {
    //list change from cross to tick
    $('.unchecked .fa').addClass('fa-times');
    $('.checked .fa').addClass('fa-check');

    $('[data-toggle="popover"]').popover();

    $('.currentUser a').click(function(){
        $('.accountSwitcher .otherAccounts').toggle('fast');
    });

    $('.emailFormTrigger').click(function(e){
        e.preventDefault();
        $(this).parent().slideUp();
        $('.signupForm').slideDown();
    });
    
    $('#selectEmailOpts li').click(function(e){
        $(this).parent().find('.active').removeClass('active');
        $(this).addClass('active');

        var open = $(this).attr('data-opts');
        $('.option').removeClass('active');
        $('.'+open).addClass('active');
        e.preventDefault();
    });

    $('#tabSwitcherPP li').click(function(){
        $(this).parent().find('li').removeClass('active');
        $(this).addClass('active');
        var open = $(this).attr('data-tab');
        $('.leadslide-service li').hide();
        $('.leadslide-service .'+open).show();
        if ($(this).attr('data-tab') == 'shopify') {
            var insert = '<div id="infoBar" class="alert alert-info">Before shopify pages will work, please add the relevent page template located at <a href="/settings/shopify" target="_blank">Shopify settings</a></div>';
            $(insert).insertBefore($('#grid'));
        } else {
            $('#infoBar').remove();
        }
    });
        //set video height
    jQuery('.homepage-hero-module').each(function(){
        var height = $(this).height;
        jQuery(this).find('video').first().css({
            height:height,
            width:'auto'
        });
    });

    var nh = $(window).height() - $('.nav-wrapper').first().height();
    $('.full-page-block').css('height', nh);
    if ($(window).width() < 720) {
        $('#inDevelopment').show();
    } else {
        $('#inDevelopment').hide();
    }
    $(window).resize(function() {
        //set video height;
        jQuery('.homepage-hero-module').each(function(){
            var height = $(this).height;
            jQuery(this).find('video').first().css({
                height:height,
                width:'auto'
            });
        });
        if ($(window).width() < 720) {
            $('#inDevelopment').show();
        } else {
            $('#inDevelopment').hide();
        }

        var nh = $(window).height() - $('.nav-wrapper').first().height();
        $('.full-page-block').css('height', nh);
    });
    //chld menu items
    $('.nav li').hover(function () {
        $(this).find('ul').show();
    }, function () {
        $(this).find('ul').hide();
    });



    //mailchimp settings
    $('#mc_list_select').change(function () {
        var long_url = $(this).find(':selected').attr('data-longurl');
        var short_url = $(this).find(':selected').attr('data-shorturl');
        var web_id = $(this).find(':selected').attr('data-webid');

        $("input[name='subscribe_url_short']").val(short_url);
        $("input[name='subscribe_url_long']").val(long_url);
        $("input[name='web_id']").val(web_id);
    });

    $.subscribe('form.submitted', function () {
        $('.flash').fadeIn(500).delay(1000).fadeOut(500, function () {
            $('.flash').html('');
        });
    });
    var include_timer = $('input[name="timed"]:checked').length;
    if (include_timer == 1) {
        $('.time-options').show();
    } else {
        $('.time-options').hide();
    }

    $('#timeControl').change(function () {
        include_timer = $('input[name="timed"]:checked').length;
        if (include_timer == 1) {
            $('.time-options').show();
        } else {
            $('.time-options').hide();
        }
    });
    var from_date = $('input[name="from"]').val();
    var to_date = $('input[name="to"]').val();
    if (from_date == "0000-00-00 00:00:00") {
        from_date = $.now();
    }

    if (to_date == "0000-00-00 00:00:00") {
        to_date = $.now();
    }
    var safe_from_date = moment(new Date(from_date));

    var safe_to_date = moment(new Date(to_date));

    $('#datetimepicker6').datetimepicker({
        format: "YYYY-MM-DD HH:mm",
        allowInputToggle: true,
        defaultDate:safe_from_date
    });
    $('#datetimepicker7').datetimepicker({
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false, //Important! See issue #1075
        allowInputToggle: true,
        defaultDate:safe_to_date
    });
    $("#datetimepicker6").on("dp.change", function (e) {
        $('#datetimepicker7').data("DateTimePicker").minDate(e.date);
    });
    $("#datetimepicker7").on("dp.change", function (e) {
        $('#datetimepicker6').data("DateTimePicker").maxDate(e.date);
    });

    $('.publishPage').click(function(e){
        e.preventDefault();
        var s_id = $(this).attr('data-id');
        $('input[name=pp_id]').val(s_id);
        $('#backShadow').show();
        $('#publishPopUp').show();
    });

    $('#publishWithUsername').click(function(e){
        e.preventDefault();
        var form = $('#publishPagesForm');
        var method = form.find('input[name="_method"]').val() || 'POST';
        publishViaAjax(form, method);
    });

    $('#publishPopUp ul.publishList').find('a').click(function(e){
        e.preventDefault();
        var type = $(this).attr('data-service');
        $('input[name=pp_type]').val(type);


        var form = $('#publishPagesForm');
        form.submit();
        //var method = form.find('input[name="_method"]').val() || 'POST';
        //publishViaAjax(form, method);
    });

    $('#checkUsername').click(function(e){
        e.preventDefault();
        var form = $('#publishPagesForm');
        var username = $('input[name=pp_username]').val();
        var url = '/user/username/check/'+username;
        $.ajax({
            type: 'GET',
            url: url,
            success: function(data){
                if (data.data.error) {
                    form.find('.flash').removeClass('alert-danger').removeClass('alert-success').removeClass('alert-info').addClass('alert-danger').html('<p>'+data.data.message+'</p>');
                } else {
                    form.find('.flash').removeClass('alert-danger').removeClass('alert-success').removeClass('alert-info').addClass('alert-success').html('<p>'+data.data.message+'</p>');
                    $('#publishWithUsername').removeAttr('disabled');
                }
            },
            error: function(data){
                console.log('ERR::-'+data);
            }
        });
    });

    $('#publishPopUp .close').click(function(){
        $('#backShadow').hide();
        $('#publishPopUp').hide();
    });

    function publishViaAjax(form, method)
    {
        //form.submit();
        $.ajax({
            type: method,
            url: form.prop('action'),
            data: form.serialize(),
            success: function(data){
                if (data.data.error) {
                    var error_number = data.data.error_num;
                    console.log(error_number);
                    switch(error_number) {
                        case 1:
                            form.show();
                            form.find('.flash').removeClass('alert-danger').removeClass('alert-success').removeClass('alert-info').addClass('alert-danger').html('<p>'+data.data.message+'</p>');

                            form.find('.flash').css({
                                top:'',
                                position:'relative',
                                width:'100%',
                                height:'50px'
                            }).fadeIn('2000');
                            break;
                    }
                } else {
                    $('#backShadow').hide();
                    $('#publishPopUp').hide();

                    $('.flash-ad').addClass('alert alert-success').html('<p>'+data.data.message+'</p>').fadeIn(2000).delay(6000)
                        .fadeOut(function() {
                            $(this).fadeOut(2000);
                            $(this).removeClass('alert alert-success');
                        });
                    if (data.data.redirect) {
                        window.location.replace(data.data.redirect);
                    }
                }
            },
            error: function(data){
                console.log('ERR::-'+data);
            }
        });
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('#copyLiquidCodeBtn').click(function(e){
        e.preventDefault();
        copyToClipboard('#liquidCopyImp');
        $('#copyMessage').fadeIn().delay(1000).fadeOut();
    });

    function copyToClipboard(element) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).val()).select();
        document.execCommand("copy");
        $temp.remove();
    }

    $('.showInstructInfo').click(function(e){
        e.preventDefault();
        if ($(this).hasClass('closed')) {
            $(this).parent().find('.infoBox').slideDown();
            $(this).addClass('open').removeClass('closed');
        } else {
            $(this).parent().find('.infoBox').slideUp();
            $(this).addClass('closed').removeClass('open');
        }
    });


})();